<template>
  <div>
    <div>
      <section class="position">
          <header class="position-header">Position</header>
          <div class="position-block cryptocss">
              <div class="position-item">
                <div class="">
                  <label class='col-6'>Entry:</label>
                  <input v-model.number="currencies[currency_index].entry"  type="number" placeholder="entry" class='col-6'>
                </div>
                <div class="">
                  <label class='col-6'>Take Profit 1:</label>
                  <input v-model.number="currencies[currency_index].tps.tp1"  type="number" placeholder="tp1" class='col-6'>
                </div>
                <div class="">
                  <label class='col-6'>Take Profit 2:</label>
                  <input v-model.number="currencies[currency_index].tps.tp2"  type="number" placeholder="tp2" class='col-6'>
                </div>
                <div class="">
                  <label class='col-6'>Take Profit 3:</label>
                  <input v-model.number="currencies[currency_index].tps.tp3"  type="number" placeholder="tp3" class='col-6'>
                </div>
                <div class="">
                  <label class='col-6'>Stop loss :</label>
                  <input v-model.number="currencies[currency_index].stop"  type="number" placeholder="stop" class='col-6'>
                </div>
                <hr>
                <div class="">
                  <label class='col-6'>Market :</label>
                   <v-select  :options="marketOptions" :searchable="false" :clearable="false" v-model="currencies[currency_index].market"></v-select>
                </div>
                <div class="">
                  <label class='col-6'>Simbolo :</label>
                  <input v-model="currencies[currency_index].symbol" placeholder="Simbolo" class='col-6'>
                </div>
                <div class="">
                  <label class='col-6'>Coin :</label>
                  <input v-model="currencies[currency_index].base" placeholder="base" class='col-6'>
                </div>
                <div class="">
                  <label class='col-6'>Base :</label>
                  <input v-model="currencies[currency_index].quote" placeholder="quote" class='col-6'>
                </div>
                <div class="">
                  <label class='col-6'>Nombre :</label>
                  <input v-model="currencies[currency_index].name" placeholder="nombre" class='col-6'>
                </div>
                <div class="">
                  <label class='col-6'>Call Price :</label>
                  <input v-model.number="currencies[currency_index].call_price" type="number"  placeholder="call price" class='col-6'>
                </div>
                <div class="">
                  <label class='col-6'>Cantidad comprada :</label>
                  <input v-model.number="currencies[currency_index].qty_bought" type="number"  placeholder="qty bought" class='col-6'>
                </div>
                <div class="">
                  <label class='col-6'>Tipo de llamada :</label>
                   <v-select  :options="dman_call_Type" :searchable="false" :clearable="false" v-model="currencies[currency_index].call_type"></v-select>
                </div>
                <div class="">
                  <label class='col-6'>Coinmarketcap id :</label>
                  <input v-model="currencies[currency_index].coinmarketcap_id" placeholder="call type bought" class='col-6'>
                </div>
                <br>

              </div>
          </div>
      </section>
    </div>
  </div>
</template>
<script>
  import { ago } from '@/util/Utility'
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
  import vSelect from 'vue-select'
  export default {
    props: ['symbol_info'],
    beforeDestroy(){
    },
    data() {
      return {
        current_orders: [],
        currency_index: 0,
        current_assets: []
      };
    },
    methods: {
      queryPairOeders () {
        doDecryp (this.binance_api_secret,this.get_orders,[this.coinPair]) 
      },
    },
    computed: {
      ...mapState(['crypto_orders','crypto_assets','currencies','marketOptions','dman_call_Type','crypto_assets'])
    },
    components: {
      vSelect,
    },
    mounted () {
      var current_symbol = this.symbol_info.symbol
      var current_market = this.symbol_info.market
      this.currency_index = this.currencies.findIndex(p => (p.symbol == current_symbol && p.market == current_market))
    },
  watch: {
    currencies: { 
        deep: true,
        handler(newValue, oldValue) {
          console.log(`Updating from ${newValue} `);
          this.$store.commit('syncCryptoCurrencies')
        },
      }
  }
    
}
</script>

<style lang="scss">
  @import "@/assets/scss/CryptoDashboard/cryptodashboard.scss";
</style>
