<template>
<div>
<CTabs variant="pills" :active-tab="1">
    <CTab title="Charts"><div class="container-fluid cryptocss">
        <div class="row flex-xl-nowrap">
            <div class="col">
                <div class="row">
                    <div class="col-md-8 mb-3">
                        <div class="info-card">
                            <div class="row">
                                <div class="col">
                                    <span class="coin-img" :style="{ backgroundImage : `url('https://maniacmansion.org/RGBanticorsCache/https://s2.coinmarketcap.com/static/img/coins/128x128/${this.symbol_info.coinmarketcap_id}.png')` }"></span>
                                    <div class="coin-name">{{currency.name}} ({{currency.base}}) / <span class="small">{{currency.quote}}</span></div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <span class="price" :style="{'color': (ticker.pchg && ticker.pchg > 0) ? 'green':'red'}">{{ticker.price}}<span class="x-small"> {{currency.quote}}</span></span>
                                </div>
                                <div class="col-md-6 chg-block" :class="[(ticker.percent < 0)?'down':'up']">
                                    <div class="text-dark small text-right">24h Chg</div>
                                    <div class="text-right d-flex justify-content-end">
                                        <span class="indicator"></span><span>{{ ticker.percent }}%</span>
                                    </div>
                                    <div class="icon-chg text-right">
                                        {{parseFloat(ticker.chg).toFixed((currency.quote === 'USDT') ? 3 : 8)}} <span class="x-small">{{currency.quote}}</span>
                                    </div>
                                </div>
                                <div class="col-12 x-small">{{ticker.time | timeformat}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mb-3">
                        <div class="info-card justify-content-center">
                            <div><span class="info-label">24H OPEN  </span>{{ ticker.open | priceformat }}<span class="small"> {{currency.quote}}</span></div>
                            <div><span class="info-label">24H HIGH  </span>{{ ticker.high | priceformat }}<span class="small"> {{currency.quote}}</span></div>
                            <div><span class="info-label">24H LOW  </span>{{ ticker.low | priceformat }}<span class="small"> {{currency.quote}}</span></div>
                            <div><span class="info-label">24H VOL  </span>{{ ticker.vol }}<span class="small"> {{currency.base}}</span></div>
                        </div>
                    </div>
                    <div class="col-12 mb-3">
                        <coin-charts :symbol_info="symbol_info"></coin-charts>
                    </div>
                </div>
            </div>
        </div>
         <div>
         </div>
    </div>

    </CTab>
    <CTab title="Position" >
            <div class="col news-position">
                <position :symbol_info="symbol_info"></position>
            </div>
    </CTab>
    <CTab title="Ordenes" >
          <div class="position-block cryptocss">
              <div class="position-item">
                <section class="position">
                    <header class="position-header">Ordenes</header>
                    <div class="position-block cryptocss">
                        <div class="position-item">
                          <div class="position-item-order"  v-for="item in current_orders"  :key="item.id" >
                                amount: {{item.amount}}<br>
                                price: {{item.price}}<br>
                                remaining: {{item.remaining}}<br>
                                side: {{item.side}}<br>
                                stopPrice: {{item.stopPrice}}<br>
                                type: {{item.type}}<hr/>
                          </div>
                        </div>
                    </div>
                </section>
          </div>
      </div>
    </CTab>
    <CTab title="Señor D">
          <div class="position-block cryptocss">
              <div class="position-item">
                  <component v-bind:is="stepComponent"> </component>
              </div>
          </div>
    </CTab>
  </CTabs>
</div>

</template>
    
<script>
  import Position from './Position.vue'
  import { mapState } from 'vuex'
  import CoinCharts from './CoinCharts.vue'
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  export default {
    props:['symbol_info'],
    name: 'info-view',
    data() {
      return {
          currentView : this.dia,
        current_orders: [],
        currency_index: 0,
        current_assets: []
      };
    },
    filters: {
      priceformat: function(value) {
        if(!value) return "";
        return parseFloat(value).toLocaleString()
      },
      timeformat: function(value) {
        if(!value) return "";
        const dt = new Date(value);
        return `${dt.getDate()} ${months[dt.getMonth()]} ${dt.toTimeString().split(' ')[0]}`
      }
    },
    components: {
      Position,
      CoinCharts,
    },
    created() {
      var _this = this
      var filteredArray = this.crypto_orders.filter(function(itm){
        return itm.symbol == _this.symbol_info.base+'/'+_this.symbol_info.quote;
      });
      this.current_orders = filteredArray
      var filteredAssets = this.crypto_assets.filter(function(itm){
        return itm.asset == _this.symbol_info.base;
      });
      this.current_assets = filteredAssets

    },
    computed: {
      ...mapState(['crypto_orders','crypto_assets','currencies','marketOptions','dman_call_Type','crypto_assets']),
      currency() {
        return this.$store.getters.getSymbolById(this.symbol_info.symbol) || {}
      },
      ticker() {
        return this.$store.getters.getTickerById(this.symbol_info.symbol) || {}
      },
      stepComponent() {
        try {
          console.log(this.symbol_info.base)
          return () =>  import('./infocards/'+this.symbol_info.base+'.vue')
          } catch (error) {
            console.error('No extra data found');
          }
      }
    }
  }
</script>

<style lang="scss">
  @import "@/assets/scss/CryptoDashboard/cryptodashboard.scss";
</style>
